import React from 'react';
import { Box, Button, Text } from '@mentimeter/ragnar-ui';
import tickerImage from 'src/images/investors/ticker.png';
import { Section, Wide } from 'src/ui/layout';
import { H2, P } from 'src/ui/typography';
import { MFWidgets, useMfClient } from './mf-client';

const Ticker = () => {
  const [data] = useMfClient<any>(MFWidgets.TICKER);
  const stock = data?.stocks?.[0];

  return (
    <Section>
      <Wide>
        <Box flexDirection={['column', null, null, 'row']} width="100%">
          <Box
            width={[null, null, null, 1 / 2]}
            my="auto"
            alignItems={['center', 'flex-start']}
          >
            <Box>
              <H2>The share</H2>
              <P>
                The Mentimeter share is quoted on the Nasdaq First North Premier
                Growth Market, with LEI code 984500C969878LA89D84.
              </P>
            </Box>
            <Button mt={2} variant="outline" href="/investors/the-share">
              Learn more
            </Button>
          </Box>
          <Box
            width={600}
            height={370}
            justifyContent="center"
            backgroundImage={`url(${tickerImage})`}
            backgroundSize="cover"
            backgroundPosition="center"
          >
            {stock && (
              <Box ml={[5, 5]}>
                <Text fontSize={[2, 3, 4]} fontWeight="semiBold">
                  NASD {stock.stockSymbol}
                </Text>
                <Text mb={0} fontSize={[5, 7, 8]} fontWeight="semiBold">
                  {stock.latestPrice} SEK
                </Text>
                <Text mb={1} fontSize={[2, 3]}>
                  {`+${stock.deltaTodaySek} (${stock.deltaToday}%)`}
                </Text>
                <Text fontSize={2} fontWeight="semiBold">
                  {`Last updated ${stock.latestDate} ${stock.latestTime}`}
                </Text>
              </Box>
            )}
          </Box>
        </Box>
      </Wide>
    </Section>
  );
};

export default Ticker;
