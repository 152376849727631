import { Box, Text } from '@mentimeter/ragnar-ui';
import { Avatar } from 'src/ui/Avatar';
import { blueLight200 } from '@mentimeter/ragnar-colors';
import type { StatementInnerT } from '../Statement';

const CarouselStatement = ({
  statement,
  image,
  attribution,
}: StatementInnerT) => (
  <Box
    flexDirection="row"
    alignItems="flex-start"
    justifyContent="center"
    p={4}
    flex="0 1 385px"
    bg="bg"
    borderColor={blueLight200}
    borderWidth="1px"
    borderStyle="solid"
    borderRadius="16px"
  >
    <Box flex="0 1 385px" height="100%" justifyContent="space-between">
      <Text fontSize={4} color="text" lineHeight="body">
        {statement}
      </Text>
      <Box>
        {attribution && (
          <Text color="text" mt={4} fontSize={2}>
            {attribution}
          </Text>
        )}
        {image && (
          <Box width="45px" height="45px" mt={3}>
            <Avatar bg="bg" url={image.src} alt={image.alt} />
          </Box>
        )}
      </Box>
    </Box>
  </Box>
);

export default CarouselStatement;
