import { Box } from '@mentimeter/ragnar-ui';
import { useReferral } from '@mentimeter/auth';
import { PlansPreviewGrid } from './components/PlansPreviewGrid';
import { usePlansConfig } from './hooks/usePlansConfig';

export const PlansReference = () => {
  const referral = useReferral();
  const config = usePlansConfig({ isEducation: false });

  const plansModified = config?.plans.map((plan) => {
    let href: string;
    let children: string;

    switch (plan.id) {
      case 'free': {
        href = `/auth/signup?referral=${referral}`;
        children = 'Get started';
        break;
      }
      case 'enterprise': {
        href = '/enterprise';
        children = 'Contact sales';
        break;
      }
      default: {
        href = '/plans';
        children = 'Learn more';
      }
    }
    return { ...plan, action: { ...plan.action, href, children } };
  });
  return (
    <Box alignItems="center" width="100%" py={5}>
      <PlansPreviewGrid plans={plansModified || []} />
    </Box>
  );
};

export default PlansReference;
