import { Device } from '@mentimeter/ragnar-device';
import type { BoxT } from '@mentimeter/ragnar-ui';
import {
  Box,
  FrameBrowser,
  FramePhone,
  RATIO_LANDSCAPE,
  RATIO_PORTRAIT,
  Text,
} from '@mentimeter/ragnar-ui';
import { Narrow, Section, Wide } from '../ui/layout';
import { H2, Subheading } from '../ui/typography';

interface Props {
  heading?: string;
  subheading?: string;
}

interface InstructionT extends BoxT {
  bullet: string;
  text: string;
}

const Instruction = ({ bullet, text, ...rest }: InstructionT) => {
  return (
    <Box flexDirection="row" alignItems="center" {...rest}>
      <Box
        borderRadius={3}
        alignItems="center"
        justifyContent="center"
        bg="positive"
        height={0}
        width="32px"
      >
        <Text color="bg" fontWeight="semiBold">
          {bullet}
        </Text>
      </Box>
      <Text ml={2}>{text}</Text>
    </Box>
  );
};
const FeatureIframe: any = ({ heading, subheading }: Props) => {
  const PHONE_ORG_WIDTH = 320;
  const PHONE_ORG_HEIGHT = 573; // Aproximately
  return (
    <Device.Match greaterThan={2}>
      <Section>
        <Narrow pb="5" alignItems="center">
          <H2 mb={3}>{heading}</H2>
          <Subheading textAlign="center">{subheading}</Subheading>
        </Narrow>
        <Wide flexDirection="row" width="100%" alignItems="center">
          <Box alignItems="center" flex={1} mr={3}>
            <Instruction
              bullet="1"
              text="Copy the code from the presentation"
              mb={3}
            />
            <FrameBrowser ratio={RATIO_LANDSCAPE}>
              <iframe
                title="Mentimeter template how did you find about Mentimeter?"
                src="https://www.mentimeter.com/app/presentation/6cf719702669c8b0a4d4bf36bc2bd183/ff5dcd9b9876/embed?no_tracking=true&intercom=disable"
                width="100%"
                height="100%"
                sandbox="allow-scripts allow-same-origin allow-forms"
              />
            </FrameBrowser>
          </Box>
          <Box
            alignItems="center"
            flex={`0 0 ${PHONE_ORG_WIDTH}px`}
            extend={() => ({
              // The negative margins make sure that the "Box" div element
              // doesn't use the original height in the dom, since transform
              // scale doesn't scale the elements dimensions, but only its content.
              '@media screen and (max-height: 900px)': {
                transform: 'scale(0.8)',
                margin: `-${(PHONE_ORG_HEIGHT * (1 - 0.8)) / 2}px -${
                  (PHONE_ORG_WIDTH * (1 - 0.9)) / 2
                }px`,
              },
              '@media screen and (max-height: 700px)': {
                transform: 'scale(0.7)',
                margin: `-${(PHONE_ORG_HEIGHT * (1 - 0.7)) / 2}px -${
                  (PHONE_ORG_WIDTH * (1 - 0.6)) / 2
                }px`,
              },
            })}
          >
            <Instruction bullet="2" text="Enter the code here" mb={3} />

            <FramePhone
              width="100%"
              ratio={RATIO_PORTRAIT}
              extend={() => ({
                // The negative margins make sure that the "Box" div element
                // doesn't use the original height in the dom, since transform
                // scale doesn't scale the elements dimensions, but only its content.
                '@media screen and (max-height: 900px)': {
                  margin: `-${(PHONE_ORG_HEIGHT * (1 - 0.9)) / 2}px 0`,
                  transform: 'scale(0.9)',
                  transformOrigin: 'center',
                },
                '@media screen and (max-height: 700px)': {
                  margin: `-${(PHONE_ORG_HEIGHT * (1 - 0.7)) / 2}px 0`,
                  transform: 'scale(0.7)',
                  transformOrigin: 'center',
                },
              })}
            >
              <iframe
                title="Menti voting"
                src="https://www.menti.com?focus=false"
                width="100%"
                height="100%"
                sandbox="allow-scripts allow-same-origin allow-forms"
              />
            </FramePhone>
          </Box>
        </Wide>
      </Section>
    </Device.Match>
  );
};

export default FeatureIframe;
