import axios from 'axios';
import React from 'react';
import type {
  DataBlockOwnerConcentration,
  DataBlockOwnerCountryDistribution,
  DataBlockOwnerHoldingDistribution,
  DataBlockOwnerList,
  DataBlockOwnerTypeDistribution,
} from './types';

export enum MFWidgets {
  CALENDAR = 'calendar',
  INSIDER_POSITIONS = 'insider-positions',
  INSIDER_TRANSACTIONS = 'insider-transactions',
  OWNER_CONCENTRATION = 'owner-concentration',
  OWNER_LIST = 'owner-list',
  OWNER_COUNTRY_DISTRIBUTION = 'owner-country-dist',
  OWNER_TYPE_DISTRIBUTION = 'owner-type-dist',
  OWNER_HOLDINGS_DISTRIBUTION = 'owner-holding-dist',
  MFN_FEED = 'mfn-feed',
  MFN_ARCHIVE = 'mfn-archive',
  STOCK_TABLE = 'stock-table',
  TICKER = 'ticker',
  STOCK_CHART = 'stock-chart',
  SUBSCRIBE_V2 = 'subscribe-v2',
  MAU_Q = 'kpi-graph',
  NUMBER_CUSTOMER_SS = 'kpi-graph',
  NUMBER_CUSTOMER_ENTP = 'kpi-graph',
  INVOICE_SALES = 'kpi-graph',
  ARR = 'kpi-graph',
}

export enum MFTokens {
  ARCHIVE = '316e1805-b5b6-4863-88f3-ae8717b4328e',
  INSIDER_POSITIONS = '5dbe6464-d938-45fd-b481-e22bd9915424',
  OWNER_CONCENTRATION = 'de18336c-c0e8-4eea-b8e0-5f5587f433fa',
  OWNER_LIST = '322c83f6-5679-4413-baac-300d681cf708',
  OWNER_COUNTRY_DISTRIBUTION = '8c41a2df-b660-4b31-a764-7bdd4331a61e',
  OWNER_TYPE_DISTRIBUTION = '4a569c24-f4ca-4e34-8a0a-2f8e59b7ef21',
  OWNER_HOLDINGS_DISTRIBUTION = 'bb1e7dc8-1e00-46f0-b2ed-45323ba5e405',
  CALENDAR = 'd9e4338a-e0f5-4653-847b-1c6cac5f3d18',
  MFN_FEED_ID = '1d9aba5e-4c81-421a-a4ba-0dba1fc383d2',
  STOCK_TABLE = '40f86b4b-51e9-4a47-b794-ae2aa479e5f6',
  TICKER = 'eedab303-b6fa-4d57-8134-0872889387af',
  STOCK_CHART = '60650c96-e5cd-4044-a76d-dc202fa8175d',
  SUBSCRIBE_V2 = 'd48b9b92-9945-41e2-b915-9334799d00ff',
  MAU_Q = 'd0dd8cca-0ea6-46e5-8e47-f9506f283a79',
  NUMBER_CUSTOMER_SS = 'c95713ab-5530-4fce-89db-db354bc2f1ad',
  NUMBER_CUSTOMER_ENTP = 'c00a3450-1c92-4694-8361-cd716e515465',
  INVOICE_SALES = '3cd506b5-0ead-4587-84a6-14a6c56961d6',
  ARR = '2e0e9742-6476-4a44-805c-db951a00dc55',
}

const MFN_URL = `https://feed.mfn.se/v1`;
const MF_URL = 'https://widget.datablocks.se/api/rose/widgets';
const MF_PROXY_URL = 'https://widget.datablocks.se/proxy';

// https://modfin.gitbook.io/datablocks/-LYRjiiPF4zveC0T_zB_/integration/request-json
export const createMfClient = () => {
  const getWidgetUrl = ({
    widget,
    token,
  }: {
    widget: MFWidgets;
    token: string;
  }) => `${MF_URL}/${widget}?token=${token}&type=json`;

  const getBabylonUrl = ({
    widget,
    token,
  }: {
    widget: MFWidgets;
    token: string;
  }) =>
    `${MF_PROXY_URL}/${widget}/${token}/en?time-location=Europe/Stockholm&languageFallback=false&demo=true`;

  const getMfnUrl = (resource: 'feed' | 'archive') =>
    `${MFN_URL}/${resource}/${MFTokens.MFN_FEED_ID}.json?limit=100&lang`;

  return {
    // Widget type: archive
    // Widget preview URL: https://widget.datablocks.se/api/rose/widgets/archive?token=316e1805-
    // b5b6-4863-88f3-ae8717b4328e
    // Widget token/id: 316e1805-b5b6-4863-88f3-ae8717b4328e
    async getArchive() {
      const url = getMfnUrl('archive');

      const res = await axios.get(url, {
        responseType: 'json',
      });
      return res.data;
    },
    // Widget type: calendar
    // Widget preview URL: https://widget.datablocks.se/api/rose/widgets/calendar?
    // token=d9e4338a-e0f5-4653-847b-1c6cac5f3d18
    // Widget token/id: d9e4338a-e0f5-4653-847b-1c6cac5f3d18
    // https://modfin.gitbook.io/datablocks/-LYRjiiPF4zveC0T_zB_/widgets/investor-calendar

    async getCalendar() {
      const url = getBabylonUrl({
        widget: MFWidgets.CALENDAR,
        token: MFTokens.CALENDAR,
      });
      const res = await axios.get(url, {
        responseType: 'json',
      });

      return res.data;
    },

    // Widget type: insider-positions
    // Widget preview URL: https://widget.datablocks.se/api/rose/widgets/insider-positions?
    // token=5dbe6464-d938-45fd-b481-e22bd9915424
    // Widget token/id: 5dbe6464-d938-45fd-b481-e22bd9915424
    // https://modfin.gitbook.io/datablocks/-LYRjiiPF4zveC0T_zB_/widgets/insider-transactions
    async getInsiderPositions() {
      const url = getWidgetUrl({
        widget: MFWidgets.INSIDER_POSITIONS,
        token: MFTokens.INSIDER_POSITIONS,
      });
      const res = await axios.get(url, {
        responseType: 'json',
      });

      return res.data;
    },

    // Widget type: insider-transactions
    // Widget preview URL: https://widget.datablocks.se/api/rose/widgets/insider-transactions?
    // token=506b1bf8-c62e-470d-94d0-2b9accff81ca
    // Widget token/id: 506b1bf8-c62e-470d-94d0-2b9accff81ca
    // https://modfin.gitbook.io/datablocks/-LYRjiiPF4zveC0T_zB_/widgets/insider-positions
    async getInsiderTransactions() {},

    async getMfnFeed(filter = '') {
      const res = await axios.get(`${getMfnUrl('feed')}${filter}`, {
        responseType: 'json',
      });

      return res.data;
    },

    // Widget type: owner-concentration
    // Widget preview URL: https://widget.datablocks.se/api/rose/widgets/owner-concentration?
    // token=de18336c-c0e8-4eea-b8e0-5f5587f433fa
    // Widget token/id: de18336c-c0e8-4eea-b8e0-5f5587f433fa
    // https://modfin.gitbook.io/datablocks/-LYRjiiPF4zveC0T_zB_/widgets/owner-concentration
    async getOwnerConcentration() {
      const url = getWidgetUrl({
        widget: MFWidgets.OWNER_CONCENTRATION,
        token: MFTokens.OWNER_CONCENTRATION,
      });
      const res = await axios.get<DataBlockOwnerConcentration>(url, {
        responseType: 'json',
      });

      return res.data;
    },
    async getOwnerList() {
      const url = getWidgetUrl({
        widget: MFWidgets.OWNER_LIST,
        token: MFTokens.OWNER_LIST,
      });
      const res = await axios.get<DataBlockOwnerList>(url, {
        responseType: 'json',
      });

      return res.data;
    },
    async getOwnerCountryDistribution() {
      const url = getWidgetUrl({
        widget: MFWidgets.OWNER_COUNTRY_DISTRIBUTION,
        token: MFTokens.OWNER_COUNTRY_DISTRIBUTION,
      });
      const res = await axios.get<DataBlockOwnerCountryDistribution>(url, {
        responseType: 'json',
      });

      return res.data;
    },
    async getOwnerTypeDistribution() {
      const url = getWidgetUrl({
        widget: MFWidgets.OWNER_TYPE_DISTRIBUTION,
        token: MFTokens.OWNER_TYPE_DISTRIBUTION,
      });
      const res = await axios.get<DataBlockOwnerTypeDistribution>(url, {
        responseType: 'json',
      });

      return res.data;
    },
    async getOwnerHoldingsDistribution() {
      const url = getWidgetUrl({
        widget: MFWidgets.OWNER_HOLDINGS_DISTRIBUTION,
        token: MFTokens.OWNER_HOLDINGS_DISTRIBUTION,
      });
      const res = await axios.get<DataBlockOwnerHoldingDistribution>(url, {
        responseType: 'json',
      });

      return res.data;
    },
    async getStockTable() {
      const url = getWidgetUrl({
        widget: MFWidgets.STOCK_TABLE,
        token: MFTokens.STOCK_TABLE,
      });
      const res = await axios.get(url, {
        responseType: 'json',
      });

      return res.data;
    },
    async getTicker() {
      const url = getWidgetUrl({
        // Same widget as stock-table but different token
        widget: MFWidgets.STOCK_TABLE,
        token: MFTokens.TICKER,
      });

      const res = await axios.get(url, {
        responseType: 'json',
      });

      return res.data;
    },
  };
};

export const useMfClient = <T>(
  endpoint: MFWidgets,
  filter?: string,
): [T | undefined, boolean] => {
  const [data, setData] = React.useState<T>();
  const [loading, setLoading] = React.useState(true);
  React.useEffect(() => {
    const loadData = async () => {
      const mf = createMfClient();
      try {
        setLoading(true);
        let nextData = null;
        switch (endpoint) {
          case MFWidgets.OWNER_CONCENTRATION:
            nextData = await mf.getOwnerConcentration();
            break;
          case MFWidgets.OWNER_LIST:
            nextData = await mf.getOwnerList();
            break;
          case MFWidgets.OWNER_COUNTRY_DISTRIBUTION:
            nextData = await mf.getOwnerCountryDistribution();
            break;
          case MFWidgets.OWNER_TYPE_DISTRIBUTION:
            nextData = await mf.getOwnerTypeDistribution();
            break;
          case MFWidgets.OWNER_HOLDINGS_DISTRIBUTION:
            nextData = await mf.getOwnerHoldingsDistribution();
            break;
          case MFWidgets.CALENDAR:
            nextData = await mf.getCalendar();
            break;
          case MFWidgets.MFN_ARCHIVE:
            nextData = await mf.getArchive();
            break;
          case MFWidgets.MFN_FEED:
            nextData = await mf.getMfnFeed(filter);
            break;
          case MFWidgets.STOCK_TABLE:
            nextData = await mf.getStockTable();
            break;
          case MFWidgets.TICKER:
            nextData = await mf.getTicker();
            break;
          case MFWidgets.INSIDER_POSITIONS:
            nextData = await mf.getInsiderPositions();
            break;
        }
        setData(nextData);
      } catch {
        // TODO: error handling
      }
      setLoading(false);
    };
    loadData();
  }, [endpoint, filter]);

  return [data, loading];
};
